import { Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { Button, Card, CircularProgress, IconButton, InputAdornment, Link, TextField, Typography } from "@mui/material";
import useLogin from "api/auth/useLogin";
import LayoutPlain from "components/LayoutPlain";
import ModalErrorLogin from "components/ModalErrorLogin";
import useToaster from "context/ToasterContext";
import { UserData } from "entities/model/user";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import { LoginParam } from "types/login";

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<LoginParam>();
  const login = useLogin();
  const { open: openToaster } = useToaster();
  const signIn = useSignIn<UserData>();
  const location = useLocation();
  const from = new URLSearchParams(location.search).get("from") || "/";

  const [show, setShow] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showErrorLogin, setShowErrorLogin] = useState(false);

  const onSubmit = async (val: LoginParam) => {
    if (login.isLoading) return;

    login.mutateAsync(val)
      .then(res => {
        if (res === undefined) {
          setShowErrorLogin(true);
          return;
        }

        if (res.data?.success) {
          if (signIn({
            auth: {
              token: res.data.data.access_token,
              type: 'Bearer',
            },
            userState: {
              clinicId: res.data.data.clinic_id,
              userId: res.data.data.user_id,
              token: res.data.data.access_token,
              clinicType: res.data.data.clinic_type
            }
          })) {
            window.location.replace(from);
            openToaster({
              title: "Selamat Datang",
              message: "Selamat bekerja kembali!",
              variant: "success",
              autoClose: true,
            });
          } else {
            throw new Error("Terjadi kesalahan saat login, silahkan coba lagi.")
          }
        } else {
          throw new Error(res.data.message || "Terjadi kesalahan saat login, silahkan coba lagi.")
        }
      })
      .catch(error => {
        openToaster({
          title: "Login Gagal",
          message: error?.message || "",
          variant: "error",
        });
      })
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  return (
    <LayoutPlain>
      <Transition appear show={show} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-5"
          enterTo="opacity-100 translate-y-0"
        >

          <Card>
            <div className="max-w-3xl flex">
              {/* left section */}
              <div className="hidden w-full bg-gradient-to-b from-light-blue-800 to-blue-900 px-8 pb-12 pt-8 sm:block">
                <div className="align-center flex h-full flex-col justify-center gap-4">
                  <div className="flex grow flex-col items-center justify-center px-6 py-8">
                    <img
                      className="m-0 mx-auto"
                      src="/img_login.svg"
                      alt="Login"
                    />
                  </div>
                  <Typography variant="h3" color="white" className="!leading-[0.9em]">
                    Partner
                    <br />
                    sehat
                    <br />
                    bersama
                  </Typography>
                </div>
              </div>

              {/* right section */}
              <div className="flex w-full shrink-0 flex-col items-center justify-center space-y-4 p-4 py-8 sm:w-1/2 sm:p-8 lg:p-12">
                <div className="w-full space-y-4 md:space-y-6">
                  <Typography variant="h4">
                    Login
                  </Typography>
                  <form
                    noValidate
                    className="space-y-4 "
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <TextField
                      label="Email"
                      placeholder="Email"
                      type="email"
                      fullWidth
                      autoFocus
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      {...register("email", {
                        validate: (value) => {
                          if (!value) {
                            return "Email tidak boleh kosong";
                          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                            return "Email tidak valid";
                          }
                          return true;
                        }
                      })}
                    />
                    <TextField
                      label="Password"
                      placeholder="Password"
                      type={isPasswordVisible ? "text" : "password"}
                      fullWidth
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                            >
                              {isPasswordVisible ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      {...register("password", {
                        validate: (value) => {
                          if (!value) {
                            return "Password tidak boleh kosong";
                          }
                          return true;
                        }
                      })}
                    />
                    <div className="flex items-center justify-end">
                      <Link
                        href="/forget-password"
                        fontSize={14}
                      >
                        Lupa password?
                      </Link>
                    </div>
                    <Button variant="contained" size="medium" type="submit" fullWidth>
                      {login.isLoading ? <CircularProgress /> : "Login"}
                    </Button>
                    <Typography fontSize={14} color="GrayText">
                      Belum punya akun?{" "}
                      <Link href="/register">
                        Daftar
                      </Link>
                    </Typography>
                  </form>
                </div>
              </div>
            </div>
          </Card>
        </Transition.Child>
      </Transition>

      <ModalErrorLogin
        open={showErrorLogin}
        onClose={() => setShowErrorLogin(false)}
      />
    </LayoutPlain>
  );
};

export default Login;
